import cx from 'classnames';
import React, { FC } from 'react';

import Footer from '../Footer';
import Nav from '../Nav';
import SEO from '../seo';
import * as styles from './Layout.module.scss';

interface LayoutProps {
  description?: string;
  image?: string;
  pagetitle?: string;
  url?: string;
  structuredData?: string;
}

const Layout: FC<LayoutProps> = ({
  children,
  description,
  image,
  pagetitle,
  structuredData,
}) => (
  <>
    <SEO
      description={description}
      image={image}
      structuredData={structuredData}
      title={pagetitle}
    />
    <div className={cx(styles.root)}>
      <Nav />
      <main className={cx(styles.main)}>{children}</main>
      <Footer />
    </div>
  </>
);

export default Layout;
