import cx from 'classnames';
import { Link } from 'gatsby';
import React, { FC } from 'react';

import * as styles from './PostList.module.scss';
import Categories from '../Categories';
import Category from '../Category';
import ListCard from '../ListCard';

type Post = {
  categories: [
    {
      title: string;
    },
  ];
  slug: {
    current: string;
  };
  title: string;
};

interface PostListProps {
  posts: [Post];
}

const PostList: FC<PostListProps> = ({ posts }) => (
  <div className={cx(styles.root)}>
    {posts.map(post => {
      const title = post.title || post.slug.current;

      return (
        <ListCard key={post.slug.current}>
          <div className={cx(styles.post)}>
            <div className={cx(styles.postTitle)}>
              <Link itemProp="url" to={`/blog/${post.slug.current}`}>
                <span itemProp="headline">{title}</span>
              </Link>
            </div>
            <Categories>
              {post.categories?.map(category => (
                <Category key={category.title}>{category.title}</Category>
              ))}
            </Categories>
          </div>
        </ListCard>
      );
    })}
  </div>
);

export default PostList;
