/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: [];
  title?: string;
  image?: string;
  structuredData?: string;
}

const Seo: FC<SEOProps> = ({
  description,
  lang = 'en',
  meta = [],
  title,
  image,
  structuredData,
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const { defaultDescription, defaultImage, defaultTitle, social } =
    site.siteMetadata;

  const siteUrl =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? `http://localhost:8000`
      : site.siteMetadata.siteUrl;

  const seo = {
    title: title || defaultTitle,
    image: `${siteUrl}${image || defaultImage}`,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: 'description',
          content: seo.description,
        },
        {
          property: 'og:title',
          content: seo.title,
        },
        {
          property: 'og:description',
          content: seo.description,
        },
        {
          property: 'og:type',
          content: 'blog',
        },
        {
          property: 'og:image',
          content: seo.image,
        },
        {
          property: 'og:url',
          content: seo.url,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: social.twitter,
        },
        {
          name: 'twitter:title',
          content: seo.title,
        },
        {
          name: 'twitter:description',
          content: seo.description,
        },
        {
          name: `twitter:image`,
          content: seo.image,
        },
      ].concat(meta)}
      title={seo.title}
      titleTemplate={seo.title ? `%s | ${seo.title}` : ''}
    >
      {structuredData && (
        <script type="application/ld+json">{structuredData}</script>
      )}
    </Helmet>
  );
};

export default Seo;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        author {
          name
          summary
        }
        defaultImage: image
        siteUrl
        social {
          twitter
        }
      }
    }
  }
`;
