import cx from 'classnames';
import React, { FC } from 'react';

import * as styles from './Categories.module.scss';

const Categories: FC = ({ children }) => (
  <div className={cx(styles.root)}>{children}</div>
);

export default Categories;
