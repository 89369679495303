import cx from 'classnames';
import React, { FC } from 'react';

import * as styles from './Title.module.scss';

interface TitleProps {
  type?: 'title' | 'subtitle';
}

const Title: FC<TitleProps> = ({ children, type = 'title' }) => (
  <>
    {type === 'title' && <h1 className={cx(styles.title)}>{children}</h1>}
    {type === 'subtitle' && <p className={cx(styles.subtitle)}>{children}</p>}
  </>
);

export default Title;
