import cx from 'classnames';
import { Link } from 'gatsby';
import React, { FC } from 'react';

import * as styles from './Nav.module.scss';

const Nav: FC = () => (
  <header>
    <nav className={cx(styles.root)}>
      <div className={cx(styles.siteName)}>
        <Link aria-label="home" to="/">
          <span>{`{ ȷ`}</span>
          <span className={cx(styles.fullName)}>osh_</span>
          <span>s</span>
          <span className={cx(styles.fullName)}>tudley</span>
          <span>&nbsp;{`}`}</span>
        </Link>
      </div>
      <div className={cx(styles.menu)}>
        <Link activeClassName={cx(styles.active)} to="/blog">
          Blog
        </Link>{' '}
        |{' '}
        <Link activeClassName={cx(styles.active)} to="/about">
          About
        </Link>
      </div>
    </nav>
  </header>
);

export default Nav;
