import cx from 'classnames';
import React, { FC } from 'react';

import * as styles from './PageHeader.module.scss';

const PageHeader: FC = ({ children }) => (
  <header className={cx(styles.root)}>
    <div className={cx(styles.headerContent)}>{children}</div>
  </header>
);

export default PageHeader;
