import cx from 'classnames';
import React, { FC } from 'react';

import * as styles from './Category.module.scss';

const Category: FC = ({ children }) => (
  <span className={cx(styles.root)}>{children}</span>
);

export default Category;
