import cx from 'classnames';
import React, { FC } from 'react';

import * as styles from './ListCard.module.scss';

const ListCard: FC = ({ children }) => (
  <div className={cx(styles.root)}>
    <div className={cx(styles.card)}>
      <div className={cx(styles.cardContent)}>{children}</div>
    </div>
  </div>
);

export default ListCard;
