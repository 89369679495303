import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import PostList from '../components/PostList';
import Title from '../components/Title';

const BlogPage = ({ data }) => (
  <Layout description="Blog posts from Josh Studley" pagetitle="Blog">
    <PageHeader>
      <Title>Blog</Title>
    </PageHeader>
    <PostList posts={data.allSanityPost.nodes} />
  </Layout>
);

export default BlogPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allSanityPost(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        _id
        categories {
          title
        }
        slug {
          current
        }
        title
      }
    }
  }
`;
